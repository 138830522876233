import Layout from "../../components/layout"
import PropTypes from "prop-types"
import React from "react"
import Seo from "../../components/seo"
import TagCloud from "../../components/tagcloud"
import { graphql } from "gatsby"

const TagsPage = ({
  data: {
    file,
    site: {
      siteMetadata
    },
  },
  location
}) => {
  const image = file.childImageSharp.gatsbyImageData
  return (
    <Layout location={location} title={siteMetadata.title} subtitle="All Article Tags" image={image} meta={siteMetadata}>
      <Seo
        title='All Tags'
        description='All Tags'
      />
      <h1>Tags</h1>
      <TagCloud filter={(tag) => !tag.value.includes('_') && tag.count > 2} />
      <hr />
    </Layout>
  )
}

TagsPage.propTypes = {
  data: PropTypes.shape({
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        title: PropTypes.string.isRequired,
      }),
    }),
  }),
}

export default TagsPage

export const pageQuery = graphql`{
  site {
    siteMetadata {
      title
      social {
        facebook
      }
    }
  }
  file(relativePath: {eq: "backgrounds/wordcloud.png"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
}
`
